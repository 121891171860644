@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Mont";
  src: url("../../fonts/Mont-Bold.eot");
  src: url("../../fonts/Mont-Bold.eot") format("embedded-opentype");
  src: url("../../fonts/Mont-Bold.woff2") format("woff2"), url("../../fonts/Mont-Bold.woff") format("woff"), url("../../fonts/Mont-Bold.ttf") format("truetype"), url("../../fonts/Mont-Bold.svg") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Mont";
  src: url("../../fonts/Mont-Light.eot");
  src: url("../../fonts/Mont-Light.eot") format("embedded-opentype");
  src: url("../../fonts/Mont-Light.woff2") format("woff2"), url("../../fonts/Mont-Light.woff") format("woff"), url("../../fonts/Mont-Light.ttf") format("truetype"), url("../../fonts/Mont-Light.svg") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Mont";
  src: url("../../fonts/Mont-Regular.eot");
  src: url("../../fonts/Mont-Regular.eot") format("embedded-opentype");
  src: url("../../fonts/Mont-Regular.woff2") format("woff2"), url("../../fonts/Mont-Regular.woff") format("woff"), url("../../fonts/Mont-Regular.ttf") format("truetype"), url("../../fonts/Mont-Regular.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Mont";
}

.close-btn img {
  width: 2vw;
}

.parent {
  height: 100vh;
  width: 1100vw;
}

p,
h1,
h2,
h3,
h4,
a {
  overflow: visible;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.app {
  overflow-x: hidden;
}

.slide {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
}

.wrapper-mouse {
  margin-left: -0.09rem;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-horizontal-scrolling-menu--scroll-container {
  overflow: hidden;
}

.width-80 {
  width: 80vw;
}

.first .img-wrapper {
  width: 25vw;
}

.first .line {
  top: -0.1vw;
  right: 0;
}

.first .lines-wrapper .first-line {
  width: 70vw;
  height: 14px;
  background-color: #ddffff;
}

.first .lines-wrapper .second-line {
  width: 70vw;
  height: 14px;
  background-color: #fbbe17;
}

.first .lines-wrapper .third-line {
  width: 70vw;
  height: 14px;
  background-color: #b8d2f0;
}

.first .text-container {
  max-width: 672px;
}

.first .text-container .first-link a {
  color: #000000;
  font-weight: 800;
  font-size: 1.354vw;
  line-height: 3.4vw;
  border-bottom: 3px solid black;
}

.first p {
  color: #231f20;
  font-size: 35px;
  font-weight: 700;
  text-align: left;
  font-style: italic;
}

.the-bar-container .the-bar {
  gap: 4vw;
}

.the-bar-container .the-bar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.second {
  height: 100vh;
}

.second .bar {
  position: absolute;
  height: 16px;
  width: 70vw;
  background-color: #ddffff;
  left: 50%;
  top: 22vw;
  transform: translate(-50%);
}

.second .line {
  top: 6.9vw;
  left: 0;
  width: 100%;
}

.second .quotes-wrapper {
  top: 24vw;
  right: 48%;
  width: 5vw;
}

.second .quotes-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.second .drawing-wrapper {
  top: 31vw;
  left: 34%;
  width: 28%;
  z-index: 2;
}

.second .drawing-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.second .big-text-wrapper {
  top: 26vw;
  left: 53%;
  z-index: 3;
}

.second .big-text-wrapper p {
  color: #231f20;
  font-size: 3.3vw;
  font-weight: 400;
  line-height: 3.9vw;
}

.second .big-text-wrapper span {
  font-weight: 800;
  font-style: italic;
}

.second .small-text-wrapper {
  top: 4.8vw;
  left: 18%;
}

.second .small-text-wrapper p {
  color: #231f20;
  font-size: 2vw;
  font-weight: 400;
  line-height: 3vw;
}

.third {
  height: 100vh;
}

.third .bar {
  position: absolute;
  height: 16px;
  width: 70vw;
  background-color: #b8d2f0;
  left: 50%;
  top: 23vw;
  transform: translate(-50%);
}

.third-legs {
  position: absolute;
  top: 37.8vw;
  width: 13vw;
  left: 61%;
  z-index: 999;
}

.third .line {
  top: 0.04vw;
}

.third .quotes-wrapper {
  top: 9vw;
  right: 47%;
  width: 5vw;
}

.third .quotes-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.third .big-text-wrapper {
  top: 10.5vw;
  left: 53%;
}

.third .big-text-wrapper p {
  color: #231f20;
  font-size: 4vw;
  font-weight: 400;
  line-height: 4.5vw;
}

.third .big-text-wrapper span {
  font-weight: 800;
  font-style: italic;
}

.third .small-text-wrapper {
  top: 28vw;
  left: 18%;
}

.third .small-text-wrapper p {
  color: #231f20;
  font-size: 2vw;
  font-weight: 400;
  line-height: 3vw;
}

.fourth .line {
  z-index: 2;
}

.fourth .quotes-wrapper {
  top: 4.5vw;
  right: 43%;
  width: 5vw;
}

.fourth .quotes-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fourth .tetris {
  bottom: 0;
  left: 52%;
  height: 400px;
  width: 400px;
}

.fourth .tetris .tetris1 {
  width: 4.167vw;
  bottom: 0;
  left: 10.5%;
}

.fourth .tetris .tetris2 {
  width: 6.406vw;
  left: 32%;
  bottom: 41px;
}

.fourth .tetris .tetris3 {
  width: 6.406vw;
  bottom: 62%;
  left: 21%;
}

.fourth .tetris .tetris4 {
  width: 4.063vw;
  bottom: 31.5%;
  left: 42.5%;
}

.fourth .tetris .tetris5 {
  width: 4.167vw;
  top: -6vw;
  left: 73%;
}

.fourth .tetris .tetris6 {
  width: 6.458vw;
  bottom: 21%;
  left: 63%;
}

.fourth .tetris .tetris7 {
  width: 4.01vw;
  bottom: 0;
  left: 42.7%;
}

.fourth .tetris .tetris8 {
  width: 1.875vw;
  bottom: 57%;
  left: 84%;
}

.fourth .tetris .tetris9 {
  width: 4.219vw;
  bottom: 31.5%;
  left: 0%;
}

.fourth .tetris .tetris10 {
  width: 4.01vw;
  bottom: 0;
  left: 73.5%;
}

.fourth .tetris .tetris11 {
  width: 1.979vw;
  left: 0;
  bottom: 0;
}

.fourth .tetris .tetris12 {
  width: 1.979vw;
  bottom: 0;
  left: 63%;
}

.fourth .tetris .tetris13 {
  width: 1.979vw;
  bottom: 31.5%;
  left: 21%;
}

.fourth .tetris .tetris14 {
  width: 1.979vw;
  bottom: 61.5%;
  left: 63%;
}

.fourth .tetris .tetris15 {
  width: 4.141vw;
  bottom: 0;
  left: 21.5%;
}

.fourth .choco-wrapper {
  bottom: 0;
  right: 33.8%;
  width: 306px;
  height: 100vh;
}

.fourth .choco-wrapper > * {
  z-index: 3;
}

.fourth .line-block {
  width: 70vw;
  left: 50%;
  transform: translate(-50%);
  top: 21.5vw;
  height: 20px;
  z-index: -1;
  background-color: #fbbe17;
}

.fourth .big-text-wrapper {
  top: 6vw;
  left: 57%;
}

.fourth .big-text-wrapper div {
  overflow: hidden;
}

.fourth .big-text-wrapper p {
  color: #231f20;
  font-size: 3vw;
  font-weight: 400;
  line-height: 3.3vw;
}

.fourth .big-text-wrapper span {
  font-weight: 800;
  font-style: italic;
}

.fourth .small-text-wrapper {
  top: 32vw;
  left: 9%;
}

.fourth .small-text-wrapper div {
  overflow: hidden;
}

.fourth .small-text-wrapper p {
  color: #231f20;
  font-size: 2vw;
  font-weight: 400;
  line-height: 3vw;
}

.fifth .line {
  top: -1.2vw;
  left: 0;
}

.fifth .quotes-wrapper {
  top: 25vw;
  left: 19%;
  width: 5vw;
}

.fifth .quotes-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fifth .legos .lego1 {
  width: 300px;
  left: 64%;
  z-index: 999999999;
  top: 63%;
}

.fifth .legos .lego2 {
  width: 255px;
  left: 65%;
  top: 20%;
}

.fifth .legos .lego3 {
  width: 163px;
  left: 82%;
  top: 43%;
}

.fifth .legos .lego4 {
  width: 95px;
  top: 26%;
  left: 82%;
}

.fifth .legos .lego5 {
  width: 95px;
  left: 62%;
  top: 52%;
}

.fifth .legos .lego6 {
  width: 95px;
  left: 77%;
  top: 50%;
}

.fifth .people-wrapper {
  z-index: 2;
  right: 10vw;
  top: 5vw;
}

.fifth .people-wrapper .text1 {
  top: 14.5vw;
}

.fifth .people-wrapper .text2 {
  top: 14.5vw;
  white-space: nowrap;
}

.fifth .people-wrapper p {
  left: 50%;
  transform: translate(-50%);
  color: #231f20;
  font-size: 1vw;
  font-weight: 400;
}

.fifth .people-wrapper__bottom {
  margin-top: -5rem;
}

.fifth .people-wrapper__bottom .text3 {
  top: 14.5vw;
  transform: rotate(-6deg) translate(-50%);
}

.fifth .people-wrapper span {
  font-weight: 700;
}

.fifth .people-wrapper .img-wrapper {
  overflow: hidden;
  width: 15.625vw;
}

.fifth .people-wrapper .img-wrapper img {
  width: 100%;
  height: 100%;
}

.fifth .people-wrapper .text3 {
  left: 60%;
}

.fifth .quote-wrapper {
  top: 25%;
  left: 50%;
  transform: translate(-50%);
  width: 97px;
}

.fifth .quote-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fifth .lines {
  right: 0;
  top: 36vw;
}

.fifth .lines > * {
  width: 85vw;
  z-index: 1;
  height: 16px;
}

.fifth .lines .first {
  background-color: #ddffff;
}

.fifth .lines .second {
  background-color: #fbbe17;
}

.fifth .lines .third {
  background-color: #b7d1ef;
}

.fifth .big-text-wrapper {
  top: 27vw;
  left: 24%;
  z-index: 5;
}

.fifth .big-text-wrapper div {
  overflow: hidden;
}

.fifth .big-text-wrapper p {
  color: #231f20;
  font-size: 3vw;
  font-weight: 400;
  line-height: 3.3vw;
}

.fifth .big-text-wrapper span {
  font-weight: 800;
  font-style: italic;
}

.fifth .sub-text-wrapper {
  top: 42.5vw;
  left: 53%;
}

.fifth .sub-text-wrapper div {
  overflow: hidden;
}

.fifth .sub-text-wrapper p {
  color: #231f20;
  font-weight: 300;
  font-size: 1.094vw;
  line-height: 1.198vw;
}

.fifth .sub-text-wrapper span {
  font-weight: 800;
}

.fifth .sub-text-wrapper .italic {
  color: #231f20;
  font-size: 21px;
  font-weight: 300;
  font-style: italic;
  font-size: 1vw;
  margin-bottom: 10px;
}

.fifth .small-text-wrapper {
  top: 7vw;
  left: 18%;
}

.fifth .small-text-wrapper div {
  overflow: hidden;
}

.fifth .small-text-wrapper p {
  color: #231f20;
  font-size: 1.5vw;
  font-weight: 400;
  line-height: 2.1vw;
}

.sixth__left {
  width: 21vw;
  height: 100vh;
}

.sixth__left .lines {
  left: 50%;
  top: 38.25vw;
  transform: translate(-50%);
}

.sixth__left .lines > * {
  width: 100%;
  z-index: 1;
  height: 16px;
}

.sixth__left .lines .first {
  background-color: #ddffff;
}

.sixth__left .lines .second {
  background-color: #fbbe17;
}

.sixth__left .lines .third {
  background-color: #b7d1ef;
}

.sixth__divider {
  height: 100vh;
  width: 16px;
  background-color: #e5e5e5;
}

.sixth__right {
  width: 100%;
  height: 100vh;
}

.sixth__right .quotes-wrapper {
  top: 10vw;
  left: 3%;
  width: 5vw;
}

.sixth__right .quotes-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sixth__right .content-container {
  top: 38.25vw;
}

.sixth__right .content-container div {
  margin-right: 12vw;
}

.sixth__right .content-container p {
  color: #231f20;
  font-size: 1.5vw;
  font-weight: 400;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sixth__right .content-container span {
  font-weight: 800;
  font-size: 1.5vw;
}

.sixth__right .big-text-wrapper {
  top: 12vw;
  left: 9%;
}

.sixth__right .big-text-wrapper div {
  overflow: hidden;
}

.sixth__right .big-text-wrapper p {
  color: #231f20;
  font-size: 3vw;
  font-weight: 400;
  line-height: 3.3vw;
}

.sixth__right .big-text-wrapper span {
  font-weight: 800;
  font-style: italic;
}

.seventh .main {
  width: 100%;
}

.seventh .portfolio {
  width: 45vw;
  top: 11.15vw;
  left: 28.4%;
}

.seventh .choco {
  width: 10.417vw;
  top: 9.67vw;
  left: 75.4%;
}

.seventh .quotes-wrapper {
  top: 21vw;
  left: 40%;
  width: 5vw;
}

.seventh .quotes-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.seventh .lines {
  left: 50%;
  top: 25.5vw;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.seventh .lines > * {
  width: 70vw;
  z-index: -1;
  height: 16px;
}

.seventh .lines .first {
  background-color: #ddffff;
}

.seventh .lines .second {
  background-color: #fbbe17;
}

.seventh .lines .third {
  background-color: #b7d1ef;
}

.seventh .text-container {
  z-index: 2;
  top: 23.5vw;
  left: 45%;
}

.seventh .text-container div {
  overflow: hidden;
}

.seventh .text-container p {
  color: #231f20;
  font-size: 2.8vw;
  font-weight: 400;
  line-height: 3.3vw;
}

.seventh .text-container span {
  font-weight: 800;
  font-style: italic;
}

.eighth {
  width: 100vw;
}

.eighth .line-left {
  top: -1.56vw;
  left: 0;
  z-index: 50;
}

.eighth .menu-btn {
  cursor: pointer;
  left: 26%;
  top: 5.6vw;
  z-index: 61;
}

.eighth .alert-enter {
  opacity: 0;
  transform: translateY(-200);
}

.eighth .alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.eighth .alert-exit {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.eighth .alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.eighth .menu-group-title {
  left: 29%;
  top: 4vw;
  z-index: 61;
  color: #231f20;
  font-family: Mont;
  font-size: 3.021vw;
  font-weight: 400;
  line-height: 3.4vw;
  border-bottom: 4px solid black;
}

.eighth__left {
  width: 25vw;
  padding: 2vw;
  height: 100vh;
  background-color: #e5e5e5;
}

.eighth__left-content {
  width: 100%;
}

.eighth__left-content img {
  margin-left: 10px;
  margin-bottom: 3.5vw;
}

.eighth__left-content h1 {
  color: #231f20;
  font-size: 24px;
  font-weight: 800;
  line-height: 23.19px;
  margin-bottom: 10px;
}

.eighth__left-content h2 {
  color: #231f20;
  font-size: 13px;
  font-weight: 500;
  line-height: 23.19px;
  margin-bottom: 16px;
}

.eighth__left-content p {
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.19px;
}

.eighth__left .lines {
  left: 50%;
  bottom: 0;
  transform: translate(-50%);
  height: 184px;
}

.eighth__left .lines > * {
  width: 20px;
  z-index: 1;
  height: 100%;
}

.eighth__left .lines .first {
  background-color: #ddffff;
}

.eighth__left .lines .second {
  background-color: #fbbe17;
}

.eighth__left .lines .third {
  background-color: #b7d1ef;
}

.eighth__right {
  width: 75vw;
}

.eighth__right .bottles {
  top: 50%;
  transform: translateY(-50%);
  left: 3%;
}

.eighth__right .drawing {
  top: 50%;
  transform: translateY(-50%);
  left: 59%;
  z-index: 2;
}

.eighth__right .images_container {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.eighth__right .images_container > * {
  width: 390px;
}

.ninth_main {
  z-index: 99;
  left: 0;
  bottom: 0;
}

.ninth__left {
  width: 20.781vw;
  height: 100vh;
  background-color: #e5e5e5;
}

.ninth__left .side-text {
  color: #231f20;
  font-size: 1.8vw;
  font-weight: 400;
  line-height: 3.3vw;
  width: 1400px;
  left: 7%;
  top: 27vw;
}

.ninth__right .quotes-wrapper {
  top: 7vw;
  left: 31%;
  width: 5vw;
}

.ninth__right .quotes-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ninth__right .lines {
  top: 36vw;
}

.ninth__right .lines > * {
  width: 100%;
  z-index: 1;
  height: 16px;
}

.ninth__right .lines .first {
  background-color: #ddffff;
}

.ninth__right .lines .second {
  background-color: #fbbe17;
}

.ninth__right .lines .third {
  background-color: #b7d1ef;
}

.ninth__right .big_text {
  top: 10vw;
  left: 37%;
}

.ninth__right .big_text div {
  overflow: hidden;
}

.ninth__right .big_text h1 {
  color: #231f20;
  font-size: 3vw;
  font-weight: 400;
  line-height: 3.8vw;
}

.ninth__right .big_text span {
  color: #231f20;
  font-weight: 800;
}

.ninth__right .logos {
  row-gap: 1rem;
  width: 77%;
  left: 50%;
  transform: translate(-50%);
  top: 27vw;
}

.ninth__right .logos div {
  overflow: hidden;
  width: calc(45px + 2.3vw);
}

.ninth__right .logos div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tenth {
  height: 100vh;
  background-color: #e5e5e5;
}

.tenth .bar {
  position: absolute;
  height: 16px;
  width: 70vw;
  background-color: #ddffff;
  left: 50%;
  top: 22vw;
  transform: translate(-50%);
}

.tenth .line {
  left: 0;
}

.tenth .quotes-wrapper {
  top: 7vw;
  right: 56%;
  width: 5vw;
}

.tenth .quotes-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tenth .drawing-wrapper {
  top: 31vw;
  left: 34%;
  width: 28%;
  z-index: 2;
}

.tenth .drawing-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tenth .lines {
  top: 36vw;
}

.tenth .lines > * {
  width: 101%;
  z-index: 1;
  height: 16px;
}

.tenth .lines .first {
  background-color: #ddffff;
}

.tenth .lines .second {
  background-color: #fbbe17;
}

.tenth .lines .third {
  background-color: #b7d1ef;
}

.tenth .big-text-wrapper {
  top: 9vw;
  left: 44%;
  z-index: 3;
}

.tenth .big-text-wrapper .img-text-wrapper img {
  width: 14.5vw;
}

.tenth .big-text-wrapper div {
  overflow: hidden;
}

.tenth .big-text-wrapper p {
  color: #231f20;
  font-size: 3vw;
  font-weight: 400;
  line-height: 3.3vw;
}

.tenth .big-text-wrapper span {
  font-weight: 800;
  font-style: italic;
}

.tenth .small-text-wrapper {
  top: 9.8vw;
  left: 16%;
}

.tenth .small-text-wrapper div {
  overflow: hidden;
}

.tenth .small-text-wrapper p {
  color: #231f20;
  font-size: 1.5vw;
  font-weight: 400;
  line-height: 2vw;
}

.eleventh {
  height: 100vh;
  background-color: #e5e5e5;
}

.eleventh .zapraszamy {
  width: 62.5vw;
  top: 5vw;
  left: 23%;
  z-index: 4;
}

.eleventh .line {
  left: 0;
}

.eleventh .contact-lines {
  width: 35.417vw;
  top: 18vw;
  right: 23%;
  z-index: 2;
}

.eleventh .circle {
  width: 40vw;
  height: 40vw;
  border-radius: 50%;
  right: 11%;
  top: 5vw;
  background-color: white;
}

.eleventh .drawing-wrapper {
  top: 31vw;
  left: 34%;
  width: 28%;
  z-index: 2;
}

.eleventh .drawing-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.eleventh .contact-wrapper {
  top: 17vw;
  right: 24%;
  z-index: 3;
}

.eleventh .contact-wrapper p {
  color: #010101;
  font-size: 1.5vw;
  font-family: Mont;
  margin-bottom: 1.87vw;
}

.eleventh .lines {
  top: 36vw;
}

.eleventh .lines > * {
  width: 40%;
  z-index: 1;
  height: 16px;
}

.eleventh .lines .first {
  background-color: #ddffff;
}

.eleventh .lines .second {
  background-color: #fbbe17;
}

.eleventh .lines .third {
  background-color: #b7d1ef;
}

.eleventh .big-text-wrapper {
  top: 17vw;
  left: 5%;
  z-index: 3;
}

.eleventh .big-text-wrapper .img-text-wrapper img {
  margin-top: -16px;
  width: 14.5vw;
}

.eleventh .big-text-wrapper div {
  overflow: hidden;
}

.eleventh .big-text-wrapper p {
  color: #231f20;
  font-size: 3vw;
  font-weight: 400;
  line-height: 3.3vw;
}

.eleventh .big-text-wrapper span {
  font-weight: 800;
  font-style: italic;
}

.eleventh .contact-wrapper {
  right: 31%;
}

.eleventh .contact-wrapper form {
  display: flex;
  flex-direction: column;
  gap: 1.9vw;
}

.eleventh .contact-wrapper form p,
.eleventh .contact-wrapper form input {
  color: #231f20;
  font-size: 1vw;
  font-weight: 400;
  background: none;
  padding: 5px;
}

@media only screen and (max-width: 1250px) {
  .eleventh .contact-wrapper form {
    gap: 1.7vw;
  }
  .eleventh .contact-wrapper form input {
    padding: 0px;
  }
}

.eleventh .contact-wrapper form .send-btn {
  position: absolute;
  bottom: -5vw;
  width: 3vw;
}

.eleventh .contact-text-wrapper {
  top: 39.8vw;
  left: 19%;
}

.eleventh .contact-text-wrapper div {
  overflow: hidden;
}

.eleventh .contact-text-wrapper p,
.eleventh .contact-text-wrapper input {
  color: #231f20;
  font-size: 1vw;
  font-weight: 400;
  line-height: 1.4vw;
}

.eleventh .contact-text-wrapper span {
  font-weight: 800;
  font-style: normal;
}

.navigation .circles-container {
  cursor: pointer;
}

.navigation .main-menu {
  height: 100vh;
  left: 0;
  top: 0;
  display: none;
  z-index: 99999;
  background-color: #e5e5e5;
  width: 30vw;
  position: fixed;
}

.navigation .main-menu .close-btn {
  cursor: pointer;
}

.navigation .main-menu a {
  color: #231f20;
  font-size: 1.563vw;
  font-weight: 400;
}

.navigation .main-menu .text-container:hover a {
  border-bottom: 2px solid black;
  transition: 0.5s;
}

.navigation .text-wrapper {
  width: 30vw;
  height: 2vw;
}

.navigation .text-wrapper .navigation-text-container {
  left: 0;
  top: 50%;
  transform: translate(0%, -50%);
}

.navigation .text-wrapper .navigation-text-container__number {
  font-weight: 800;
}

.navigation-logo img {
  width: 109px;
}

@media screen and (max-width: 1024px) {
  .navigation-logo img {
    width: 85px;
  }
}

.navigation .medium {
  width: 0.469vw;
}

.navigation .small {
  width: 0.365vw;
}

.navigation .big {
  width: 0.521vw;
}

@media screen and (max-width: 1024px) {
  .navigation .text-wrapper {
    width: 30vw;
    height: 6vw;
  }
  .navigation .medium {
    width: 6px;
  }
  .navigation .small {
    width: 5px;
  }
  .navigation .big {
    width: 7px;
  }
  .navigation .main-menu {
    height: 100vh;
    left: 0;
    top: 0;
    display: none;
    z-index: 99999;
    background-color: #e5e5e5;
    width: 80vw;
    position: fixed;
  }
  .navigation .main-menu .close-btn {
    cursor: pointer;
  }
  .navigation .main-menu a {
    color: #231f20;
    font-size: 4.563vw;
    font-weight: 400;
  }
}

@media screen and (max-width: 500px) {
  .navigation .text-wrapper {
    width: 80vw;
    height: 6vw;
  }
}

.wrapper {
  z-index: 9999999;
}

.wrapper .choco {
  width: 200px;
}

.wrapper .choco-wrapper {
  width: 100px;
}

.wrapper .choco-wrapper .mobile-wrapper_choco {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.wrapper .mouse-wrapper {
  height: 104px;
}

.wrapper .mouse-wrapper .mouse {
  width: 36px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.wrapper .mouse-wrapper .mouse-arrow1 {
  width: 15px;
  top: 0;
  left: 50%;
  transform: translate(-50%) rotate(-90deg);
}

.wrapper .mouse-wrapper .mouse-arrow2 {
  width: 15px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%) rotate(90deg);
}

.wrapper .forward-wrapper {
  cursor: pointer;
  width: 50px;
}

.wrapper .forward-wrapper .forward-circle {
  width: 54px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper .forward-wrapper .forward-arrow {
  width: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper-mobile {
  pointer-events: none;
  z-index: 9999999;
}

.wrapper-mobile .choco-wrapper {
  width: 65px;
}

.wrapper-mobile .choco-wrapper .mobile-wrapper_choco {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.wrapper-mobile .mouse-wrapper {
  height: 75px;
}

.wrapper-mobile .mouse-wrapper .mouse {
  width: 26px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.wrapper-mobile .mouse-wrapper .mouse-arrow1 {
  width: 11px;
  top: 0;
  left: 50%;
  transform: translate(-50%) rotate(-90deg);
}

.wrapper-mobile .mouse-wrapper .mouse-arrow2 {
  width: 11px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%) rotate(90deg);
}

.wrapper-mobile .forward-wrapper {
  width: 50px;
}

.wrapper-mobile .forward-wrapper .forward-circle {
  width: 37px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper-mobile .forward-wrapper .forward-arrow {
  width: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
}

.mobile .text-small div {
  overflow: hidden;
}

.mobile .text-small p {
  color: #231f20;
  font-family: Mont;
  font-size: 3.8vw;
  font-weight: 400;
  line-height: 5vw;
}

.mobile .text-small span {
  font-weight: 800;
}

.mobile .text-big div {
  overflow: hidden;
}

.mobile .text-big p {
  color: #231f20;
  font-family: Mont;
  font-size: 6.5vw;
  font-weight: 400;
  line-height: 6.8vw;
}

.mobile .text-big span {
  font-weight: 800;
  font-style: italic;
}

.mobile .grey-divider {
  background-color: #e5e5e5;
  width: 100%;
  height: 20px;
}

.mobile .quotes {
  width: 11vw;
}

.mobileFirst {
  height: 752vw;
}

.mobileFirst .first-line {
  top: 146vw;
  width: 100%;
  z-index: 7;
}

.mobileFirst .first-section {
  height: 180vw;
  background-color: #e5e5e5;
  width: 100%;
}

.mobileFirst .first-section .img-wrapper {
  width: 37vw;
}

.mobileFirst .first-section .the-bar-container {
  top: 41vw;
  overflow: hidden;
  left: 50%;
  transform: translate(-50%);
}

.mobileFirst .first-section .lines-wrapper {
  top: 58.5vw;
  left: 50%;
  transform: translate(-50%);
}

.mobileFirst .first-section .lines-wrapper .first-line {
  width: 100vw;
  height: 1.563vw;
  background-color: #ddffff;
}

.mobileFirst .first-section .lines-wrapper .second-line {
  width: 100vw;
  height: 1.563vw;
  background-color: #fbbe17;
}

.mobileFirst .first-section .lines-wrapper .third-line {
  width: 100vw;
  height: 1.563vw;
  background-color: #b8d2f0;
}

.mobileFirst .first-section .text-container__header p {
  color: #231f20;
  font-size: 6.836vw;
  font-weight: 800;
  text-align: left;
  line-height: 8vw;
}

.mobileFirst .first-section .check-us {
  top: 124vw;
  left: 40%;
}

.mobileFirst .first-section .check-us p {
  color: #231f20;
  font-size: 7vw;
  font-weight: 800;
  text-align: left;
  text-decoration: underline;
}

.mobileFirst .legs-leg1 {
  left: 28%;
  top: 432vw;
  width: 14.746vw;
}

.mobileFirst .legs-leg2 {
  left: 15%;
  top: 441vw;
  width: 9.375vw;
}

.mobileFirst-divider1 {
  right: 0;
  top: 251vw;
}

.mobileFirst-divider1-bar {
  background-color: #ddffff;
  height: 39px;
  width: 90vw;
}

.mobileFirst-divider2 {
  left: 0;
  top: 435vw;
}

.mobileFirst-divider2-bar {
  width: 90vw;
  background-color: #b8d2f0;
  height: 39px;
}

.mobileFirst-divider3 {
  top: 632vw;
}

.mobileFirst-divider3-bar {
  width: 100vw;
  background-color: #fbbe17;
  height: 39px;
}

.mobileFirst .brainstorm {
  width: 59.961vw;
  top: 263vw;
  right: 0;
}

.mobileFirst .tetris {
  width: 70.371vw;
  top: 592vw;
  height: 37vw;
  left: 55%;
  z-index: 999;
  overflow: hidden;
}

.mobileFirst .tetris .tetris1 {
  width: 7.813vw;
  bottom: 0;
  left: 4.5vw;
}

.mobileFirst .tetris .tetris2 {
  width: 12.012vw;
  left: 13.5vw;
  bottom: 4vw;
}

.mobileFirst .tetris .tetris3 {
  width: 12.012vw;
  bottom: 25vw;
  left: 8.5vw;
}

.mobileFirst .tetris .tetris4 {
  width: 7.617vw;
  bottom: 12.5vw;
  left: 18.3vw;
}

.mobileFirst .tetris .tetris5 {
  width: 7.813vw;
  top: -11vw;
  left: 30.3vw;
}

.mobileFirst .tetris .tetris6 {
  width: 12.109vw;
  bottom: 8.5vw;
  left: 26.4vw;
}

.mobileFirst .tetris .tetris7 {
  width: 7.617vw;
  bottom: 0;
  left: 17.5vw;
}

.mobileFirst .tetris .tetris8 {
  width: 3.516vw;
  bottom: 22vw;
  left: 35vw;
}

.mobileFirst .tetris .tetris9 {
  width: 7.91vw;
  bottom: 12.5vw;
  left: 0%;
}

.mobileFirst .tetris .tetris10 {
  width: 7.52vw;
  bottom: 0;
  left: 30.5vw;
}

.mobileFirst .tetris .tetris11 {
  width: 3.711vw;
  left: 0;
  bottom: 0;
}

.mobileFirst .tetris .tetris12 {
  width: 3.711vw;
  bottom: 0;
  left: 26vw;
}

.mobileFirst .tetris .tetris13 {
  width: 3.711vw;
  bottom: 12.5vw;
  left: 8.5vw;
}

.mobileFirst .tetris .tetris14 {
  width: 3.711vw;
  bottom: 24vw;
  left: 26.2vw;
}

.mobileFirst .tetris .tetris15 {
  width: 7.764vw;
  bottom: 0;
  left: 9vw;
}

.mobileFirst .quotes-section1-first {
  top: 300vw;
  left: 17%;
}

.mobileFirst .quotes-section1-second {
  top: 468vw;
  left: 28%;
}

.mobileFirst .quotes-section1-third {
  top: 644vw;
  left: 21%;
}

.mobileFirst .text-container__first {
  top: 206vw;
  left: 0%;
}

.mobileFirst .text-container__second {
  top: 306vw;
  left: 28%;
}

.mobileFirst .text-container__third {
  top: 392vw;
  left: 6%;
}

.mobileFirst .text-container__fourth {
  top: 474vw;
  left: 39%;
}

.mobileFirst .text-container__fifth {
  top: 562vw;
  left: 48%;
}

.mobileFirst .text-container__sixth {
  top: 650vw;
  left: 33%;
}

.mobileFirst .text-container__seventh {
  top: 738vw;
  left: 3%;
}

.mobileFirst .text-container__header {
  top: 75vw;
  left: 26%;
}

.mobileFirst .text-container__header div {
  overflow: hidden;
}

.mobileSecond {
  height: 200vw;
}

.mobileSecond .banner {
  right: 3vw;
  height: 200vw;
  z-index: 4;
}

.mobileSecond .banner__line1 {
  background-color: #ddffff;
  width: 3vw;
}

.mobileSecond .banner__line2 {
  background-color: #fbbe17;
  width: 3vw;
}

.mobileSecond .banner__line3 {
  background-color: #b8d2f0;
  width: 3vw;
}

.mobileSecond .legos .lego1 {
  width: 68vw;
  left: 9%;
  z-index: 999999999;
  top: 139vw;
}

.mobileSecond .legos .lego2 {
  width: 52vw;
  left: 30%;
  top: 45vw;
}

.mobileSecond .legos .lego3 {
  width: 30vw;
  left: 53%;
  top: 104vw;
}

.mobileSecond .legos .lego4 {
  width: 20vw;
  top: 121vw;
  left: 17%;
}

.mobileSecond .legos .lego5 {
  width: 20vw;
  left: 26%;
  top: 97vw;
}

.mobileSecond .legos .lego6 {
  width: 19vw;
  left: 8%;
  top: 68vw;
}

.mobileSecond .people-container div {
  overflow: hidden;
}

.mobileSecond .people-container__first {
  top: 28vw;
  left: 13%;
}

.mobileSecond .people-container__first .basia-wrapper {
  width: 46.484vw;
}

.mobileSecond .people-container__first .basia-wrapper img {
  width: 100%;
  height: 100%;
}

.mobileSecond .people-container__second {
  top: 62vw;
  left: 31%;
  z-index: 6;
}

.mobileSecond .people-container__second .basia-wrapper {
  width: 46.484vw;
}

.mobileSecond .people-container__second .basia-wrapper img {
  width: 100%;
  height: 100%;
}

.mobileSecond .people-container__third {
  top: 94vw;
  left: 4%;
  z-index: 6;
  width: 46.484vw;
}

.mobileSecond .people-container__third img {
  width: 100%;
  height: 100%;
}

.mobileSecond .people-container__fourth {
  top: 107vw;
  left: 35%;
  z-index: 6;
  width: 46.484vw;
}

.mobileSecond .people-container__fourth img {
  width: 100%;
  height: 100%;
}

.mobileSecond .people-container__text {
  top: 150vw;
  left: 14%;
  transform: rotate(8deg);
}

.mobileSecond .sub-text {
  top: 170vw;
  left: 14%;
}

.mobileSecond .sub-text h4 {
  color: #231f20;
  font-size: 3.125vw;
  font-weight: 300;
}

.mobileSecond .sub-text p {
  font-size: 3.2vw;
}

.mobileSecond .quotes-section2-first {
  top: 3vw;
  left: 70%;
}

.mobileSecond .text-container2__first {
  top: 12vw;
  left: 27%;
}

.mobileThird .first-section {
  height: 94vw;
}

.mobileThird .second-section {
  background-color: #e5e5e5;
  height: 139vw;
}

.mobileThird .first-line {
  top: -2vw;
}

.mobileThird .second-line {
  top: 222vw;
}

.mobileThird .text-medium {
  color: #231f20;
  font-family: Mont;
  font-size: 4vw;
  font-weight: 700;
}

.mobileThird .portfolio {
  top: 142vw;
  left: 50%;
  transform: translate(-50%);
  width: 89.746vw;
  transform: translate(-50%);
}

.mobileThird .choco {
  z-index: 4;
  top: 162vw;
  left: 50%;
  width: 21.094vw;
  transform: translate(-50%);
}

.mobileThird .third-section-lines {
  width: 100vw;
  top: 170vw;
}

.mobileThird .third-section-lines .third-first-line {
  background-color: #b8d2f0;
  height: 3vw;
}

.mobileThird .third-section-lines .third-second-line {
  background-color: #fbbe17;
  height: 3vw;
}

.mobileThird .third-section-lines .third-third-line {
  background-color: #ddffff;
  height: 3vw;
}

.mobileThird .quotes-section3__first {
  top: 6vw;
  left: 8%;
}

.mobileThird .alert-enter {
  opacity: 0;
  transform: translateY(-200);
}

.mobileThird .alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.mobileThird .alert-exit {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.mobileThird .alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.mobileThird .menu-btn {
  width: 9vw;
  height: 9vw;
  position: absolute;
  z-index: 999;
  top: 273vw;
  left: 68%;
  cursor: pointer;
}

.mobileThird .mobile-menu-title {
  top: 280vw;
  font-size: 6.5vw;
}

.mobileThird .mobile-about-project {
  top: 292vw;
  font-size: 3vw;
}

.mobileThird .mobile-about-project h1 {
  font-weight: 800;
}

.mobileThird .mobile-about-project h2 {
  font-weight: 600;
  margin-bottom: 3vw;
}

.mobileThird .text-container__first {
  top: 12vw;
  left: 19%;
}

.mobileThird .text-container__second {
  top: 41vw;
  left: 16%;
}

.mobileThird .text-container__third {
  top: 195vw;
  left: 50%;
  transform: translate(-50%);
}

.mobileFourth {
  height: 338vw;
  overflow: hidden;
}

.mobileFourth .line1 {
  top: 0;
}

.mobileFourth .line2 {
  z-index: 3;
  top: 168vw;
}

.mobileFourth .grey-place {
  width: 100vw;
  height: 45vw;
  background-color: #e5e5e5;
}

.mobileFourth .grey-place2 {
  width: 100vw;
  height: 354vw;
  top: 190vw;
  background-color: #e5e5e5;
}

.mobileFourth .circle {
  width: 85vw;
  height: 85vw;
  border-radius: 50%;
  right: -10%;
  top: 214vw;
  background-color: white;
  z-index: 4;
}

.mobileFourth .banner {
  right: 3vw;
  height: 206vw;
  z-index: 4;
  top: 40vw;
}

.mobileFourth .banner__line1 {
  background-color: #ddffff;
  width: 3vw;
}

.mobileFourth .banner__line2 {
  background-color: #fbbe17;
  width: 3vw;
}

.mobileFourth .banner__line3 {
  background-color: #b8d2f0;
  width: 3vw;
}

.mobileFourth .banner-horizontal {
  top: 40vw;
  left: 0;
  width: 100%;
  z-index: 5;
}

.mobileFourth .banner-horizontal__line1 {
  background-color: #ddffff;
  height: 3vw;
  width: 88vw;
}

.mobileFourth .banner-horizontal__line2 {
  background-color: #fbbe17;
  height: 3vw;
  width: 91vw;
}

.mobileFourth .banner-horizontal__line3 {
  background-color: #b8d2f0;
  height: 3vw;
  width: 94vw;
}

.mobileFourth .logos-wrapper {
  top: 111vw;
  left: 50%;
  gap: 13vw;
  width: 85vw;
  transform: translate(-50%);
  row-gap: 2rem;
}

.mobileFourth .logos-wrapper div {
  width: 12vw;
}

.mobileFourth .logos-wrapper div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mobileFourth .stars {
  top: 259vw;
  left: 31%;
  width: 32.617vw;
}

.mobileFourth .quotes-section__first {
  top: 57vw;
  left: 17%;
}

.mobileFourth .quotes-section__second {
  top: 195vw;
  left: 8.5%;
}

.mobileFourth .zapraszamy-title {
  left: 5%;
  top: 179vw;
  width: 78.125vw;
}

.mobileFourth .text-container__first {
  top: 59vw;
  left: 31%;
}

.mobileFourth .text-container__second {
  top: 241vw;
  left: 17%;
}

.mobileFourth .text-container__third {
  top: 198vw;
  left: 20.5%;
  z-index: 999;
}

.mobileFourth .text-container__third .img-text-wrapper img {
  width: 35.449vw;
}

.mobileFourth .text-container__fourth {
  top: 403vw;
  left: 24.5%;
  z-index: 5;
}

.mobileFourth .text-container__fifth {
  top: 500vw;
  left: 14.5%;
  z-index: 5;
}

.mobileFourth .contact-wrapper {
  top: 244vw;
  right: 21%;
  z-index: 7;
}

@media only screen and (max-width: 321px) {
  .mobileFourth .contact-wrapper {
    top: 442vw;
  }
}

.mobileFourth .contact-wrapper__contact p {
  color: #010101;
  font-size: 3.5vw;
  font-family: Mont;
  margin-bottom: 3.5vw;
}

.mobileFourth .contact-wrapper form {
  display: flex;
  flex-direction: column;
  gap: 4.7vw;
}

.mobileFourth .contact-wrapper form p,
.mobileFourth .contact-wrapper form input {
  font-size: 3.125vw;
  font-weight: 400;
  line-height: 3.9vw;
  background: none;
  padding: 0px;
}

@media only screen and (max-width: 595px) {
  .mobileFourth .contact-wrapper form {
    gap: 4.2vw;
  }
}

@media only screen and (max-width: 525px) {
  .mobileFourth .contact-wrapper form {
    gap: 3.7vw;
  }
}

@media only screen and (max-width: 488px) {
  .mobileFourth .contact-wrapper form {
    gap: 3.1vw;
  }
}

@media only screen and (max-width: 488px) {
  .mobileFourth .contact-wrapper form {
    gap: 2.6vw;
  }
}

@media only screen and (max-width: 321px) {
  .mobileFourth .contact-wrapper form {
    gap: 0.3vw;
  }
}

.mobileFourth .contact-wrapper form .send-btn {
  position: absolute;
  bottom: -17vw;
  width: 9vw;
}

.mobileFourth .send-button {
  top: 478vw;
  left: 42%;
  z-index: 7;
  width: 7.813vw;
}

.mobileFourth .contact-lines {
  width: 72.417vw;
  top: 247vw;
  right: 16%;
  z-index: 7;
}

.mobileFourth .text-contact p {
  color: #000000;
  font-size: 3.125vw;
  font-weight: 400;
  line-height: 3.9vw;
}

.mobileFourth .text-contact span {
  font-weight: 800;
}

.menu {
  background-color: #e5e5e5;
  left: 24%;
  top: 9vw;
  z-index: 62;
}

.menu a {
  color: #231f20;
  font-size: 1.563vw;
  font-weight: 400;
}

.menu-item-active {
  border-bottom: 2px solid black;
}

.menu-item-passive {
  border-bottom: none;
}

.menu-mobile {
  background-color: #e5e5e5;
  left: 0;
  top: 280vw;
  z-index: 62;
  width: 100%;
}

.menu-mobile a {
  color: #231f20;
  font-size: 3.5vw;
  font-weight: 400;
}

.swiper-wrapper {
  z-index: 60 !important;
}

.swiper-wrapper .swiper-container {
  width: 100%;
}

.swiper-wrapper .swiper {
  width: 100%;
  height: 100%;
  z-index: 61 !important;
}

.swiper-wrapper .swiper-button-prev, .swiper-wrapper .swiper-button-next {
  color: grey;
  z-index: 61 !important;
}

.swiper-wrapper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  z-index: 61 !important;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-wrapper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper-wrapper .swiper-slide video {
  display: block;
  width: 75%;
  object-fit: contain;
}

.swiper-wrapper .swiper-slide .special-vid {
  width: 45%;
}

.mobileThird .swiper-wrapper {
  height: 90vw;
}

.mobileThird .swiper-wrapper .swiper {
  width: 100%;
  height: 100%;
}

.mobileThird .swiper-wrapper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileThird .swiper-wrapper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mobileThird .swiper-wrapper .swiper-slide video {
  display: block;
  width: 75%;
  object-fit: contain;
}

.mobileThird .swiper-wrapper .swiper-slide .special-vid {
  width: 45%;
}
